<template>
  <div class="view">
    <top-nav active="1" :isRectangle="true" :istop="true"></top-nav>
    <div class="topnav_bg"></div>
    <div class="products">
      <div class="agency first">
        <el-row >
          <el-col :span="14">
            <img class="agency_logo" src="../../assets/imgs/pc/arc-logo.png" alt="">
            <p class="agency_name">ARC Agent</p>
            <p class="agency_info">
              ARC is a fast, mobile based property platform that is designed and developed for project marketing professionals. It carries the grand mission to achieve true automation and powerful network effects in a virtual marketplace where developers, property agents, mortgage brokers, solicitors and buyers could all connect and make deals happen.
            </p>
            <div class="line_btn">
              <span class="Download">Download</span>
              <el-popover
                placement="top-start"
                width="160"
                offset="55"
                trigger="hover"
                >
                 <img @click="goiPhone" style="width:150px;padding-left:5px;cursor: pointer;" src="../../assets/imgs/pc/AppStore_QR.png" alt="">
                
                <span slot="reference" style="padding-top:5px">
                 <img style="width:35px;vertical-align: -66%;cursor: pointer;margin:0 20px;" src="../../assets/imgs/pc/iPhone.png" alt="">
                </span>
              </el-popover>
                <el-popover
                placement="top-start"
                offset="75"
                width="160"
                trigger="hover"
                >
                 <img @click="goiPhone" style="width:150px;padding-left:5px;cursor: pointer;" src="../../assets/imgs/pc/GooglePlay_QR.png" alt="">
                
                <span slot="reference" style="padding-top:5px">
                 <img style="width:35px;vertical-align: -66%;cursor: pointer;padding-left:0px" src="../../assets/imgs/pc/Android.png" alt="">
                </span>
              </el-popover>
              <!-- <span class="QRcodeAndroid btn">
                 <img style="width:35px;vertical-align: -36%;padding-left:25px" src="../../assets/imgs/pc/Android.png" alt="">
                  <div id="QRcodeImg2" @click="goAndroid">
                  </div>
              </span> -->
              <!-- <img style="width:35px;vertical-align: -86%;padding-left:25px" src="../../assets/imgs/pc/Android.png" alt=""> -->
              <span style="padding-left:40px" class="btn">
                <a target="_blank" href="https://agent.arcnet.com.au/">Web Login</a>
                </span>
                
            </div>
          </el-col>
          <el-col :span="10">
            <img class="agency_nav"  src="../../assets/imgs/pc/products_01.png" alt="">
          </el-col>
        </el-row>
      </div>
      <!-- 2 -->
      <div class="agency">
        <el-row >
          <el-col :span="14">
            <img class="agency_logo" src="../../assets/imgs/pc/arc-logo.png" alt="">
            <p class="agency_name">ARC Partner</p>
            <p class="agency_info">
              ARC Partner Portal, is currently a web based platform designed and developed to track leads, to and from referral partners, and digital loan submission for the dedicated Equitymate (little to zero deposit) loan product. 
The portal provides the dedicated lead generation funnel via the Fact find affiliate links. The inbuilt links provide the users more power to generate leads via social media and from referral partners, with all information merging into the ARC CRM.
            </p>
            <div class="line_btn">
              <span class="btn">
                 <a target="_blank" href="https://partner.arcnet.com.au/">User Login</a>
                </span>
            </div>
          </el-col>
          <el-col :span="10">
            <img class="agency_nav"  src="../../assets/imgs/pc/products_02.png" alt="">
          </el-col>
        </el-row>
      </div>
      <!-- 3 -->
      <div class="agency">
        <el-row >
          <el-col :span="14">
            <img class="agency_logo" src="../../assets/imgs/pc/ragonvr.png" alt="">
            <p class="agency_name">DragonVR</p>
            <p class="agency_info">
              Based both in Sydney and Shenzhen, we’ve gained the reputation as a fast turnaround and high quality development team. We provide our clients with cost-effective solutions in real estate VR production. 
            </p>
            <p class="agency_info">
              Our teams are made up of members who carry high level of VR expertise and experience in project marketing. This allows us and the vendors to seamlessly collaborate to create state of the art VR experience across all of client’s digital touch points.  
            </p>
            <div class="line_btn">
               <span class="btn">
                 <a target="_blank" href="https://www.dragonvr.info/">Learn More</a>
                </span>
            </div>
          </el-col>
          <el-col :span="10">
            <img class="agency_nav"  src="../../assets/imgs/pc/products_03.png" alt="">
          </el-col>
        </el-row>
      </div>
      <!-- 4 -->
      <div class="agency">
        <el-row >
          <el-col :span="14">
            <img class="agency_logo" src="../../assets/imgs/pc/equilymate.png" alt="">
            <p class="agency_name">EquityMate</p>
            <p class="agency_info">
             A dedicated program to assist Australian first home buyers and investors to purchase a property with little to zero deposit. EquityMate's digital processing is exclusive to the ARC platform.
            </p>
            <div class="line_btn">
              <span class="btn"><a target="_blank" href="https://www.equitymate.com.au/#/?originatortype=1&originatorid=1448662277">Learn More</a></span>
            </div>
          </el-col>
          <el-col :span="10">
            <img class="agency_nav"  src="../../assets/imgs/pc/products_04.png" alt="">
          </el-col>
        </el-row>
      </div>
      <!-- end -->
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
export default {
  data(){
    return{
      active:'1',
    }
  },
  created(){
  
  },
  methods:{
    goRouter(path){
      this.$router.push(path)
    },
    goiPhone(){
      window.open('https://play.google.com/store/apps/details?id=au.com.arcnet.agent')
      // window.location.href = ""
    },
    goAndroid(){
      window.open('https://apps.apple.com/au/app/arc-agent/id1510107896')
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "foot-nav": resolve => require(["./FooterNav.vue"], resolve),
  },
}
</script>
<style lang="scss">
.view {
  background: #f7f7f7;
}
  .products{
    min-height: calc(100vh - 208px);
    background: #f7f7f7;
    padding: 0 96px;
    padding-bottom: 120px;
    .agency{
      margin-top: 49px;
      text-align: left;
      padding: 50px;
      background: #FFF;
      position: relative;
      .agency_logo {
        height: 46px;
      }
      .agency_name {
        font-size: 30px;
        padding-top: 55px;
        padding-bottom: 15px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: #000000;
      }
      .agency_info {
        font-family: Poppins;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.4px;
        color: #1e1d1e;
        padding-bottom: 25px;
      }
      .line_btn {
        position: absolute;
        bottom: 0;
        .btn {
          height: 18px;
          padding: 0 40px 0 0;
          font-family: Poppins;
          cursor: pointer;
          display: inline-block;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.4px;
          color: #5291f7;
          a{
            font-size: 18px;
            color: #5291f7;
          }
        }
        .Download {
          height: 18px;
          // padding: 0 40px 0 0;
          font-family: Poppins;
          display: inline-block;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.4px;
          color: #1e1d1e;
        }
      }
      .agency_nav {
        width: 100%;
        padding-top: 50px;
      }
    }
    .first{
      margin-top: 84px;
    }
  }
  #QRcodeImg2{
        background: url('../../assets/imgs/pc/GooglePlay_QR.png') no-repeat 0px 0px;
        width: 150px;
        height: 150px;
        position: absolute;
        top: -150px;
        z-index:22;
        left: -20px;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }
    #QRcodeImg1{
        background: url('../../assets/imgs/pc/AppStore_QR.png') no-repeat 0px 0px;
        width: 150px;
        top: -150px;
        height: 150px;
        position: absolute;
        left: -50px;
        z-index:22;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }
    .QRcodeiPhone{
      position: relative;
    }
    .QRcodeAndroid{
      position: relative;
    }
    .QRcodeiPhone:hover #QRcodeImg1{
        display:block;
    }
    .QRcodeAndroid:hover #QRcodeImg2{
      display:block;
    }
</style>